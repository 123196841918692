import React from "react";
import { Button, IconButton, Loader, Modal } from "rsuite";
import ChangeListIcon from "@rsuite/icons/ChangeList";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import Divider from "rsuite/Divider";
import { exportAddAtom } from "../../../Atoms/exportAdd.atom";
import { useRecoilState } from "recoil";
import ExportExcel from "./excelExport";

export default function ExportAdd({
  AddComponent,
  excelData,
  nameExcel,
  noExport,
  save,
  noAdd,
  ActionOnClose,
  size,
  addTitle = "Modal Title",
}) {
  const handleOpen = () =>
    setstate((prev) => {
      return { ...prev, open: true };
    });
  const [state, setstate] = useRecoilState(exportAddAtom);
  const handleClose = () =>
    setstate((prev) => {
      return { ...prev, open: false };
    });
  const exportExcel = () => {
    document.querySelector("#hidden-btn-export").click();
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "15px 0",
        zIndex: "1",
      }}
    >
      {!noExport && (
        <Button color="green" onClick={exportExcel} appearance="primary">
          <ChangeListIcon /> Export
        </Button>
      )}
      <Divider vertical />
      {!noAdd && (
        <>
          <IconButton
            onClick={handleOpen}
            appearance="primary"
            icon={<PlusRoundIcon />}
          >
            Ajout
          </IconButton>

          <Modal
            style={{ maxHeight: "95vh", overflow: "auto" }}
            size={size ? size : "lg"}
            overflow={false}
            open={state.open}
            onClose={() => {
              handleClose();
              if (ActionOnClose) ActionOnClose();
            }}
          >
            <Modal.Header>
              <Modal.Title>{addTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{AddComponent}</Modal.Body>
            <Modal.Footer>
              <Button onClick={save} appearance="primary">
                {state.loading ? <Loader size="sm" /> : "Enregistrer"}
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  if (ActionOnClose) ActionOnClose();
                }}
                appearance="subtle"
              >
                Annuler
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <ExportExcel data={excelData} name={nameExcel} />
    </div>
  );
}
